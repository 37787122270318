import React from "react"
import {Link} from "gatsby"
import LogoPink from '@svg/logo-pink'
import ZnajdzNasNaFb from '@svg/znajdznasnafb'
import ZnanyLekarz from '@svg/znanylekarz'

import {menu} from '@content/menu.yaml'

const Menu = () => {
  return (
    <nav className="nav-primary">
      <div className="circle expand"/>
      <div className="nav-primary__logo">
        <Link to={"/"}>
          <LogoPink/>
        </Link>
      </div>
      <div className="nav-primary__content">
        <div className="nav-primary__content--scroll">
          <ul id="menu-menu-1" className="nav">
            {menu.map((item, key) => (
              <li
                className="menu-item menu-item-type-post_type menu-item-object-page animate"
                key={key}
              >
                <Link to={item.link} aria-current="page">
                  {item.title}
                </Link>
              </li>
            ))}
            <li style={{marginTop: '1rem'}}>
              <span style={{fontWeight: 'bold', fontSize: '15px', paddingLeft: '4.5rem', borderLeft: '6px solid transparent', color: "#555"}}>Rejestracja online</span>
              <a href="/zapisy" style={{fontSize: '17px', color: '#D3218D'}}>Ginekologia/Ciąża</a>
              <a href="https://rejestracja.medfile.pl/register/index/?uuid=9d05cf34-25ce-01f7-2f9b-bd88580cf051" style={{fontSize: '17px', color: '#D3218D', paddingRight: 0, lineHeight: '24px'}}>Dermatologia/<br />Medycyna estetyczna</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="nav-primary__socials">
        <Link
          to={"https://www.facebook.com/tiliamed/"}
          target="_blank"
          rel="nofollow"
        >
          <ZnajdzNasNaFb />
        </Link>
        <Link
          to={"https://www.znanylekarz.pl/michal-lipa/ginekolog/legionowo"}
          target="_blank"
          className="btn"
          rel="nofollow"
        >
          <ZnanyLekarz />
        </Link>
      </div>
      <button className="nav-primary__close js-nav-off" id="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642">
          <path
            fillRule="evenodd"
            d="M8.882 7.821l6.541-6.541A.75.75 0 1 0 14.362.219L7.821 6.76 1.28.22A.75.75 0 1 0 .219 1.281L6.76 7.822l-6.54 6.54a.75.75 0 0 0 1.06 1.061l6.541-6.541 6.541 6.541a.75.75 0 1 0 1.06-1.061l-6.54-6.541z"
          />
        </svg>
      </button>
    </nav>
  )
}

export default Menu
