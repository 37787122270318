import React from "react"
import {Link} from 'gatsby'

const Css = () => {
  return (
    <style>
    {`
      .slick-slide > div  {
        margin: 0 auto;
      }
    `}
    </style>
  )
}

export default Css
