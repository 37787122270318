import React from "react"
import PropTypes from "prop-types"
import '../../styles/app.scss'

import Menu from "./menu"
import Footer from "./footer"
import Header from "./header"
import Cookies from "./cookies"
import MobileAddress from "./mobile-address"
import Css from "./css"

const Layout = ({ children }) => {
  return (
    <div className="wrap" role="document">
      <Css />
      <Header />
      <Menu />
      {children}
      <Footer />
      <Cookies />
      <MobileAddress />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
