import { withPrefix } from "gatsby"

export const appleTouchIconsPrecomposed = [
  "57x57",
  "114x114",
  "72x72",
  "144x144",
  "60x60",
  "120x120",
  "76x76",
  "152x152",
].map(size => ({
  rel: "apple-touch-icon-precomposed",
  sizes: size,
  href: withPrefix("images/apple-touch-icon-" + size + ".png"),
}))

export const favicons = ["196x196", "96x96", "32x32", "16x16", "128x128"].map(
  size => ({
    rel: "icon",
    sizes: size,
    type: "image/png",
    href: withPrefix("images/favicon-" + size + ".png"),
  })
)

export const msapplication = [
  "TileImage",
  "square70x70logo",
  "square150x150logo",
  "wide310x150logo",
  "square310x310logo",
].map(icon => ({
  name: "msapplication-" + icon,
  content: withPrefix("images/mstile-" + icon + ".png"),
}))
