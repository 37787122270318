import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { withPrefix } from "gatsby-link"
import {
  appleTouchIconsPrecomposed,
  favicons,
  msapplication,
} from "./seo/icons"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `x-ua-compatible`,
          content: "id=edge",
        },
        {
          name: `viewport`,
          content: "width=device-width, initial-scale=1",
        },
        {
          name: `format-detection`,
          content: "telephone=no",
        },
        {
          name: `keywords`,
          content:
            "Ginekologia, lekarz ginekolog, poradnia ginekologiczna, gabinet ginekologiczny",
        },
        {
          name: "msapplication-TileColor",
          content: "#fff",
        },
        ...msapplication,
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: `pl_PL`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `/`,
        },
        {
          property: `og:image`,
          content: withPrefix(
            "wp-uploads/2017/01/tiliamed_preview-284-of-317-e1487536700112.jpg"
          ),
        },
        {
          property: `og:image:secure_url`,
          content: withPrefix(
            "wp-uploads/2017/01/tiliamed_preview-284-of-317-e1487536700112.jpg"
          ),
        },
        {
          property: `og:image:width`,
          content: `300`,
        },
        {
          property: `og:image:height`,
          content: `200`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: withPrefix(
            "wp-uploads/2017/01/tiliamed_preview-284-of-317-e1487536700112.jpg"
          ),
        },
        {
          name: 'google-site-verification',
          content: 'KkaEmQy0_MJX5ZQ-y5hM9XMfA79oytIGil2WdIONLfE'
        }
      ].concat(meta)}
      link={[
        ...appleTouchIconsPrecomposed,
        ...favicons,
        {
          rel: "shortlink",
          href: "/",
        },
      ]}
      script={[
        {
          type: "text/javascript",
          defer: true,
          src: withPrefix("scripts/app.js"),
        },
        {
          type: "text/javascript",
          defer: true,
          src: withPrefix("scripts/shortcodes-from-html.js"),
        },
        {
          type: "text/javascript",
          defer: true,
          src: withPrefix("scripts/wp-emoji.js"),
        },
        {
          type: "text/javascript",
          defer: true,
          src: withPrefix("scripts/wp-emoji-from-html.js"),
        },
        {
          type: "text/javascript",
          defer: true,
          src: withPrefix("scripts/hoverintent.js"),
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

export default SEO
