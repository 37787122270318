import React from "react"
import {Link, withPrefix} from "gatsby"

const Cookies = () => (
  <div className="ad-modal" data-autohidemodalafter={120} data-cookieexdays={7}>
    <div className="inner">
      <button className="desktop js-ad-modal-close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642">
          <path fillRule="evenodd" d="M8.882 7.821l6.541-6.541A.75.75 0 1 0 14.362.219L7.821 6.76 1.28.22A.75.75 0 1 0 .219 1.281L6.76 7.822l-6.54 6.54a.75.75 0 0 0 1.06 1.061l6.541-6.541 6.541 6.541a.75.75 0 1 0 1.06-1.061l-6.54-6.541z" />
        </svg>
      </button>
      <Link to={"/oferta/usg-3d-4d/"}>
        <img className="mobile" src={withPrefix(`wp-uploads/2018/08/pop-mobile.jpg`)} alt="Grafika modal tło" />
        <img className="desktop" src={withPrefix(`wp-uploads/2018/08/pop-desktop.jpg`)} alt="Grafika modal" />
      </Link>
    </div>
    <button className="mobile js-ad-modal-close-btn">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642">
        <path fillRule="evenodd" d="M8.882 7.821l6.541-6.541A.75.75 0 1 0 14.362.219L7.821 6.76 1.28.22A.75.75 0 1 0 .219 1.281L6.76 7.822l-6.54 6.54a.75.75 0 0 0 1.06 1.061l6.541-6.541 6.541 6.541a.75.75 0 1 0 1.06-1.061l-6.54-6.541z" />
      </svg>
    </button>
    <div className="adm-modal-bg js-ad-modal-close" />
  </div>
)

export default Cookies
